export const PATH = {
  MAIN: '/',
  REGISTER: '/registration',
  MAINBOARD: '/mainboard',
  DASHBOARD: '/mainboard/dashboard',
  RECORD_HISTORY: '/mainboard/record-history',
  InsertData: '/mainboard/data-entry',
  SearchData: '/mainboard/search-data',
  TRACK_HISTORY: '/mainboard/track-history',
  REPORT: '/mainboard/report',
  PRODUCT_VENDOR: '/mainboard/product-vendor',
  // ADMINISTRATION: '/mainboard/administration',
  SETTINGS: '/mainboard/settings',
  DEAL: '/mainboard/deal',
  DEAL_DETAILS: '/mainboard/deal/:dealId',
  CONTRIBUTION: '/mainboard/contribution',
  CONTRIBUTION_DETAIL: '/mainboard/contribution/contribution-detail/:contributionId',
  DISCOVER: '/mainboard/discover',
  DISCOVER_DETAILS: '/mainboard/discover/:projectId',
  DISCOVER_CALENDAR: '/mainboard/discover/calendar',
  GOVERNANCE: '/mainboard/governance',
  PROPOSAL: '/mainboard/governance/proposal/:id',
  KOHORT: '/mainboard/governance/kohort',
  ADMINLOGIN: '/login',
  ADMINMAINBOARD: '/admin-mainboard',
  USERAPPLICANTS: '/admin-mainboard/user/applicants',
  USERMEMBERS: '/admin-mainboard/user/members',
  USERCOMMITTES: '/admin-mainboard/user/committes',
  USERKOHORTS: '/admin-mainboard/user/kohorts',
  DEALROOM: '/admin-mainboard/deal/dealroom',
  DEALINTEREST: '/admin-mainboard/deal/dealinterest',
  ADMINDISCOVER: '/admin-mainboard/discover',
  PROFILE: '/mainboard/dashboard/profile',
  PROFILE_UPDATE: '/mainboard/dashboard/profile/profile-update',
  DEAL_INTEREST: '/mainboard/deal/deal-details/:dealId/interest',
  CREATE_PROPOSAL: '/create-proposal',
  ADMINCONTRIBUTION: '/admin-mainboard/contribution',
  ADMINDEAL: '/admin-mainboard/deal/dealroom',
};
