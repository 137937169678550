import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const DashboardContainer = styled.div`
  display: flex;
  height: 100%; /* 부모 컨테이너의 높이를 100%로 설정 */
  min-height: 100vh; /* 최소 높이를 화면 전체 높이로 설정 */
`;

export const SidebarContainer = styled.div`
  width: 220px;
  height: 100vh;
  flex-shrink: 0;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
  position: sticky;
  top: 0;
  transition: width 0.3s ease;
  border-right: 1px solid #c1c1c1; /* 오른쪽 테두리 */

  @media (max-width: 1024px) {
    width: 64px; /* 너비를 줄여 아이콘만 보이도록 설정 */
    align-items: center; /* 중앙 정렬 */
  }
`;

export const NavMain = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 10px 10px 50px 10px;
  width: 100%;
  color: #1a0737; /* 기본 텍스트 색상 */
  text-decoration: none;
  transition: padding 0.3s ease, background-color 0.3s ease, color 0.3s ease;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 5px 5px; /* 여기에 추가 */
  padding: 13px 6px; /* 여기에 추가 */
  width: calc(100% - 10px); /* 너비를 조정하여 여백 포함 */
  color: #1a0737;
  text-decoration: none;
  transition: all 0.3s ease;
  &.active {
    background-color: #4c51cd; /* 활성 상태 배경색 */
    border-radius: 3px; /* 모서리 둥글게 처리 (옵션) */
  }

  @media (max-width: 1024px) {
    justify-content: center; /* 작은 화면에서 아이콘만 중앙에 배치 */
  }
`;

export const Icon = styled.div<{ $imageUrl: string; $activeImageUrl?: string; $isActive?: boolean }>`
  width: 24px;
  height: 24px;
  background: url(${(props) => (props.$isActive && props.$activeImageUrl ? props.$activeImageUrl : props.$imageUrl)})
    no-repeat center center;
  background-size: contain;
  transition: all 0.3s ease; /* 부드러운 전환 효과 추가 */

  @media (max-width: 1024px) {
    width: 32px; /* 작은 화면에서 아이콘 크기 키우기 */
    height: 32px;
  }
`;

export const Name = styled.div<{ $isActive?: boolean }>`
  color: ${(props) => (props.$isActive ? 'white' : 'black')}; /* 활성 상태일 때 색상 변경 */
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: color 0.3s ease, font-size 0.3s ease;

  @media (max-width: 1024px) {
    display: none; /* 작은 화면에서 이름 숨기기 */
  }
`;

export const NameMain = styled.div<{ $isActive?: boolean }>`
  color: ${(props) => (props.$isActive ? '#000080' : '#000080')}; /* 활성 상태일 때 색상 변경 */
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: color 0.3s ease, font-size 0.3s ease;

  @media (max-width: 1024px) {
    display: none; /* 이름 숨기기 */
  }
`;
