import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/store';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import Wallet from 'src/components/walletbtn/WalletComponent';
import SearchBar from 'src/components/dashboard/discover/SearchBar';
import ValidationContainer from 'src/components/dashboard/record_history/ValidationBox';
import ValidationButtons from 'src/components/button/ValidationButtons';
import ExcelUpload from 'src/components/button/ExcelUpload';
import ExcelDownload from 'src/components/button/ExcelDownload';
// import InsertData from 'src/components/button/InsertData';
import * as Styled from './RecordHistory.styles';
import { setUserData } from 'src/store/user/UserSlice'; // UserSlice 추가

interface ExcelData {
  id: number;
  생성일: string;
  제품명: string;
  제품ID: string;
  CTE: string;
  TLC: string;
  코드생성위치: string;
  수량: number;
  상태?: string;
}

const RecordHistory: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [excelData, setExcelData] = useState<ExcelData[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSaveData = (data: any) => {
    console.log('Data saved:', data);
    // Save the data as needed
  };
  const handleSearch = (term: string) => {
    setSearchTerm(term.toLowerCase());
  };

  const filteredData = excelData.filter((record) =>
    Object.values(record).some((value) => value.toString().toLowerCase().includes(searchTerm))
  );

  const handleValidate = async () => {
    const updatedData = excelData.map((record) => {
      if (selectedRecords.includes(record.id)) {
        return { ...record, 상태: 'Compliant' };
      }
      return record;
    });

    setExcelData(updatedData);
    setSelectedRecords([]);
  };

  const handleSave = async () => {
    try {
      const selectedData = excelData.filter((record) => selectedRecords.includes(record.id));
      const response = await axios.post('/api/save', { data: selectedData });
      console.log('Records saved:', response.data);
      setSelectedRecords([]);
    } catch (error) {
      console.error('Error saving records:', error);
    }
  };

  const handleFileUpload = (data: ExcelData[]) => {
    setExcelData(data);
  };

  const toggleRecordSelection = (id: number) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const isSaveActive = selectedRecords.some((recordId) => {
    const record = excelData.find((row) => row.id === recordId);
    return record && record.상태 === 'Compliant';
  });

  const isValidateActive =
    selectedRecords.length > 0 &&
    selectedRecords.some((id) => {
      const record = excelData.find((row) => row.id === id);
      return record && record.상태 !== 'Compliant';
    });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user.wallet_addr) {
        // 기본 데이터 설정
        dispatch(
          setUserData({
            wallet_addr: 'N/A',
          })
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [user.wallet_addr, dispatch]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Styled.RecordHistoryContainer>
      <Styled.RecordHistoryHeader>
        <Styled.RecordHistoryTitle>RecordHistory</Styled.RecordHistoryTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </Styled.RecordHistoryHeader>
      <Styled.SearchBarWrapper>
        <SearchBar onSearch={handleSearch} />
        <ValidationButtons
          onValidate={handleValidate}
          onSave={handleSave}
          isValidateActive={isValidateActive}
          isSaveActive={isSaveActive}
        />
      </Styled.SearchBarWrapper>
      <Styled.ValidationWrapper>
        <ValidationContainer />
      </Styled.ValidationWrapper>
      <Styled.BtnContainer>
        <Styled.ModalBtn $primary={true} onClick={handleOpenModal}>
          데이터 입력
        </Styled.ModalBtn>
        <ExcelDownload />
        {/* <ExcelUpload onUpload={handleFileUpload} /> */}
      </Styled.BtnContainer>

      {/* {showModal && <InsertData onClose={handleCloseModal} onSave={handleSaveData} />} */}

      <Styled.Table>
        <thead>
          <tr>
            {excelData.length > 0 && (
              <Styled.CheckboxHeader>
                <Styled.CheckboxWrapper>
                  <Styled.CheckboxInput
                    onChange={(e) => setSelectedRecords(e.target.checked ? excelData.map((row) => row.id) : [])}
                    checked={selectedRecords.length === excelData.length}
                  />
                </Styled.CheckboxWrapper>
              </Styled.CheckboxHeader>
            )}
            <Styled.TableHeader>생성일</Styled.TableHeader>
            <Styled.TableHeader>제품명</Styled.TableHeader>
            <Styled.TableHeader>제품ID</Styled.TableHeader>
            <Styled.TableHeader>CTE</Styled.TableHeader>
            <Styled.TableHeader>TLC</Styled.TableHeader>
            <Styled.TableHeader>코드생성위치</Styled.TableHeader>
            <Styled.TableHeader>수량</Styled.TableHeader>
            <Styled.TableHeader>상태</Styled.TableHeader>
          </tr>
        </thead>
        {excelData.length > 0 ? (
          <tbody>
            {filteredData.map((row) => (
              <tr key={row.id}>
                <Styled.CheckboxCell>
                  <Styled.CheckboxWrapper>
                    <Styled.CheckboxInput
                      checked={selectedRecords.includes(row.id)}
                      onChange={() => toggleRecordSelection(row.id)}
                    />
                  </Styled.CheckboxWrapper>
                </Styled.CheckboxCell>
                <Styled.TableCell>{row.생성일}</Styled.TableCell>
                <Styled.TableCell>{row.제품명}</Styled.TableCell>
                <Styled.TableCell>{row.제품ID}</Styled.TableCell>
                <Styled.TableCell>{row.CTE}</Styled.TableCell>
                <Styled.TableCell>{row.TLC}</Styled.TableCell>
                <Styled.TableCell>{row.코드생성위치}</Styled.TableCell>
                <Styled.TableCell>{row.수량}</Styled.TableCell>
                <Styled.StatusCell status={row.상태}>{row.상태}</Styled.StatusCell>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <Styled.StyledNoDataCell colSpan={8}>
                <Styled.NoDataMessage>Please upload data.</Styled.NoDataMessage>
              </Styled.StyledNoDataCell>
            </tr>
          </tbody>
        )}
      </Styled.Table>
    </Styled.RecordHistoryContainer>
  );
};

export default RecordHistory;
