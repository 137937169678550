import React from 'react';
import styled from 'styled-components';

interface ExcelModal2Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}

const ExcelModal2: React.FC<ExcelModal2Props> = ({ isOpen, closeModal, onConfirm }) => {
  if (!isOpen) {
    console.log('Modal is not open, returning null');
    return null;
  }

  console.log('Modal is open, rendering');
  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <ModalHeader>신규 TLC 연결</ModalHeader>
        <ModalBody>
          <ModalMessage>
            <MessageLine>
              <HighlightedText>[프레쉬농장]</HighlightedText> 2024-10-10 12:08:00{' '}
              <HighlightedText>출고</HighlightedText>가
            </MessageLine>
            기록되었습니다.
            <MessageLine>
              해당 데이터를 <HighlightedText>입고</HighlightedText>와 연결하시겠습니까?
            </MessageLine>
          </ModalMessage>
        </ModalBody>
        <ButtonsContainer>
          <ConfirmButton onClick={onConfirm}>연결하기</ConfirmButton>
          <CancelButton onClick={closeModal}>닫기</CancelButton>
        </ButtonsContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ExcelModal2;

// 스타일
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  width: 450px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 25px;
  position: relative;
  text-align: center;
  font-family: 'Arial', sans-serif;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
`;

const ModalHeader = styled.h2`
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 2px solid silver;
  padding-bottom: 10px;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const ModalMessage = styled.div`
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const MessageLine = styled.div`
  margin-bottom: 5px;
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #4a90e2;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const ConfirmButton = styled.button`
  background: #4a90e2;
  color: white;
  padding: 10px 40px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #357abd;
  }
`;

const CancelButton = styled.button`
  background: #f5f5f5;
  color: #333;
  width: 120px;
  padding: 10px 40px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #e0e0e0;
  }
`;
