// src/pages/dashboard/Sidebar.tsx
import { PATH } from '../../../constants/path';
import { SidebarContainer, NavMain, NavItem, Icon, Name, NameMain } from '../../dashboard/sidebar/Sidebar.style';
import { images } from '../../../assets/dashboard/images';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  onGovernanceClick: () => void; // 모달 열기 함수
}

const Sidebar: React.FC<SidebarProps> = ({ onGovernanceClick }) => {
  const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoClick = () => {
    navigate('/mainboard/dashboard'); // 메인 페이지 경로로 이동
  };

  return (
    <SidebarContainer>
      {/* <Logo style={{ backgroundImage: `url(${images.sidebarLogo})` }} onClick={handleLogoClick} /> */}
      <NavMain to={PATH.DASHBOARD}>
        {({ isActive }) => (
          <>
            <Icon $imageUrl={images.varIcon} />
            <NameMain $isActive={isActive}>Food 4 Chain</NameMain>
          </>
        )}
      </NavMain>
      <NavItem to={PATH.DASHBOARD}>
        {({ isActive }) => (
          <>
            <Icon $imageUrl={images.dashboardIcon} $activeImageUrl={images.dashboardIconActive} $isActive={isActive} />
            <Name $isActive={isActive}>대시보드</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.InsertData}>
        {({ isActive }) => (
          <>
            <Icon
              $imageUrl={images.recordhistoryIcon}
              $activeImageUrl={images.recordhistoryIconActive}
              $isActive={isActive}
            />
            <Name $isActive={isActive}>데이터 입력</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.SearchData}>
        {({ isActive }) => (
          <>
            <Icon $imageUrl={images.searchdata} $activeImageUrl={images.searchdataActive} $isActive={isActive} />
            <Name $isActive={isActive}>데이터 조회</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.TRACK_HISTORY}>
        {({ isActive }) => (
          <>
            <Icon
              $imageUrl={images.trackhistoryIcon}
              $activeImageUrl={images.trackhistoryActive}
              $isActive={isActive}
            />
            <Name $isActive={isActive}>이력추적</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.REPORT}>
        {({ isActive }) => (
          <>
            <Icon $imageUrl={images.reportIcon} $activeImageUrl={images.reportActive} $isActive={isActive} />
            <Name $isActive={isActive}>보고서 생성</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.PRODUCT_VENDOR}>
        {({ isActive }) => (
          <>
            <Icon
              $imageUrl={images.productvendorIcon}
              $activeImageUrl={images.productvendorActive}
              $isActive={isActive}
            />
            <Name $isActive={isActive}>식품 시설 등록</Name>
          </>
        )}
      </NavItem>
      <NavItem to={PATH.SETTINGS}>
        {({ isActive }) => (
          <>
            <Icon $imageUrl={images.settingsIcon} $activeImageUrl={images.settingsActive} $isActive={isActive} />
            <Name $isActive={isActive}>환경설정</Name>
          </>
        )}
      </NavItem>
    </SidebarContainer>
  );
};

export default Sidebar;
