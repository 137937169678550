import styled from 'styled-components';
import checkedSVG from 'src/assets/record-history/checked.svg';

interface ModalBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $primary?: boolean;
}

export const RecordHistoryContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

export const RecordHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 20px;
`;

export const RecordHistoryTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

export const ValidationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  padding: 20px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
`;

export const TableHeader = styled.th`
  background-color: #d9d9d9;
  color: black;
  padding: 12px;
  text-align: center;
  width: 120px;
`;

export const CheckboxHeader = styled(TableHeader)`
  width: 50px;
`;

export const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  width: 120px;
`;

export const CheckboxCell = styled(TableCell)`
  width: 50px;
`;

export const StatusCell = styled(TableCell)<{ status?: string }>`
  color: ${({ status }) => {
    if (status === 'Compliant') return '#34A44F';
    if (status === 'non pass') return 'red';
    return 'black';
  }};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;

  &:checked {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url(${checkedSVG}) no-repeat center center;
      background-size: contain;
    }
  }
`;

export const NoDataMessage = styled.div`
  margin: 20px 0;
  font-size: 16px;
  color: #666;
`;

export const BtnContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledNoDataCell = styled(TableCell)`
  background-color: #f9f9f9;
  color: #999;
  font-style: italic;
`;

export const ModalBtn = styled.button<ModalBtnProps>`
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  border-radius: 4px;
  transition: background-color 0.2s;
  margin-left: 10px;
  width: 200px;
  font-size: 16px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
