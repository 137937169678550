import React, { useState } from 'react';
import {
  AdminBoard,
  Container,
  Title,
  FormGroup,
  SubTitle,
  Form,
  Input,
  ForgotPassword,
  Button,
  Label,
  PasswordWrapper,
  EyeIcon,
  LogoTitle,
} from './adminLogin.style';
import eyeIcon from 'src/assets/admin/eye-off.svg';
import Logo from 'src/assets/dashboard/Logo.png';
import { useDispatch } from 'react-redux';
import { setUserData } from 'src/store/user/UserSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const validUsers = [
      { email: 'farmer@futuresense.co.kr', password: '1234' },
      { email: 'factory@futuresense.co.kr', password: '1234' },
    ];

    const isValidUser = validUsers.some((user) => user.email === email && user.password === password);

    if (isValidUser) {
      // 로그인 성공 시 상태 업데이트 및 페이지 이동
      dispatch(setUserData({ email_addr: email })); // 이메일을 Redux store에 저장
      navigate('/mainboard/data-entry');
    } else {
      // 로그인 실패 시 오류 메시지 설정
      toast.error('Invalid email or password');
    }
  };

  return (
    <AdminBoard>
      <Container>
        <LogoTitle src={Logo}></LogoTitle>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email Address</Label>
            <Input type="email" value={email} onChange={handleEmailChange} required />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <PasswordWrapper>
              <Input
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <EyeIcon src={eyeIcon} alt="Toggle visibility" onClick={togglePasswordVisibility} />
            </PasswordWrapper>
          </FormGroup>

          <ForgotPassword>Forgot password?</ForgotPassword>
          <Button type="submit">Sign In</Button>
        </Form>
      </Container>
    </AdminBoard>
  );
};

export default AdminLogin;
