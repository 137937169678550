import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  gap: 8px; /* 박스들 간의 간격 */

  @media (max-width: 1024px) {
    justify-content: space-evenly;
  }

  @media (max-width: 760px) {
    justify-content: center;
  }

  @media (max-width: 400px) {
    justify-content: flex-start;
  }
`;

// transient prop을 사용하여 bgColor를 전달
const Box = styled.div<{ $bgColor: string }>`
  background-color: #f4f4f4;
  border-radius: 2px;
  padding: 12px;
  width: 220px;
  height: 46px;
  gap: 4px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* 반응형 레이아웃 */
  @media (max-width: 1024px) {
    width: calc(33.33% - 8px); /* 3개씩 2열 */
  }

  @media (max-width: 760px) {
    width: calc(50% - 8px); /* 2개씩 3열 */
  }

  @media (max-width: 400px) {
    width: 100%; /* 1개씩 6열 */
  }
`;

const Title = styled.h3<{ color: string }>`
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  color: ${(props) => props.color};
`;

const Count = styled.p`
  width: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #bbbbc3; // 카운트 색상
`;

interface BoxData {
  title: string;
  count: number;
  color: string; // 배경 색상 추가
  textColor: string; // 텍스트 색상 추가
}

// 박스 데이터 배열
const boxData: BoxData[] = [
  { title: 'Total', count: 0, color: '#f44336', textColor: '#0F0F0F' }, // 빨간색 배경, 검정 텍스트
  { title: 'Compliant', count: 0, color: '#4caf50', textColor: '#34A44F' }, // 초록색 배경, 연두색 텍스트
  { title: 'Non-compliant', count: 0, color: '#ff9800', textColor: '#DA1414' }, // 주황색 배경, 빨간색 텍스트
  { title: 'Critical', count: 0, color: '#9c27b0', textColor: '#DA1414' }, // 보라색 배경, 빨간색 텍스트
  { title: 'Warning', count: 0, color: '#ffeb3b', textColor: '#EC6600' }, // 노란색 배경, 오렌지색 텍스트
  { title: 'Pending', count: 0, color: '#2196f3', textColor: '#2F54EB' }, // 파란색 배경, 파란색 텍스트
];

const ValidationContainer: React.FC = () => {
  return (
    <Container>
      {boxData.map((box, index) => (
        <Box key={index} $bgColor={box.color}>
          <Title color={box.textColor}>{box.title}</Title>
          <Count>{box.count}</Count>
        </Box>
      ))}
    </Container>
  );
};

export default ValidationContainer;
