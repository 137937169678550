import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { images } from '../../../src/assets/dashboard/images';

interface FilterBar2Props {
  onSearch: () => void; // Callback function to handle the "추적" button click
}

const FilterBar2: React.FC<FilterBar2Props> = ({ onSearch }) => {
  const [activeRange, setActiveRange] = useState<string>('오늘');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [productSearch, setProductSearch] = useState<string>('');
  const [tlcSearch, setTlcSearch] = useState<string>('');
  const [productSuggestions, setProductSuggestions] = useState<string[]>([]);
  const [showProductSuggestions, setShowProductSuggestions] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const predefinedSuggestions = ['야채비빔밥', '야채김밥', '야채샐러드', '야채스프', '야채튀김'];

  const handleRangeClick = (range: string) => {
    setActiveRange(range);
  };

  const handleProductSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProductSearch(value);

    if (value.trim().length > 0) {
      const filteredSuggestions = predefinedSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setProductSuggestions(filteredSuggestions);
      setShowProductSuggestions(filteredSuggestions.length > 0);
    } else {
      setShowProductSuggestions(false);
    }
  };

  const handleProductSuggestionClick = (suggestion: string) => {
    setProductSearch(suggestion);
    setShowProductSuggestions(false);
  };

  const handleSearchSubmit = () => {
    if (productSearch.trim()) {
      onSearch(); // Trigger the onSearch callback from the parent (TabComponent)
    }
    console.log('검색 실행:', {
      productSearch,
      tlcSearch,
      startDate,
      endDate,
      activeRange,
    });
    setShowProductSuggestions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowProductSuggestions(false); // 검색창 외부 클릭 시 추천 숨기기
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <DateRange>
        <Button active={activeRange === '오늘'} onClick={() => handleRangeClick('오늘')}>
          오늘
        </Button>
        <Button active={activeRange === '1주일'} onClick={() => handleRangeClick('1주일')}>
          1주일
        </Button>
        <Button active={activeRange === '1개월'} onClick={() => handleRangeClick('1개월')}>
          1개월
        </Button>
        <Button active={activeRange === '3개월'} onClick={() => handleRangeClick('3개월')}>
          3개월
        </Button>
        <Button active={activeRange === '전체'} onClick={() => handleRangeClick('전체')}>
          전체
        </Button>
      </DateRange>

      <DateFilter>
        <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <Separator>~</Separator>
        <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <CalendarIcon />
      </DateFilter>

      <SearchFilter ref={searchRef}>
        <SearchWrapper>
          <SearchInput
            type="text"
            placeholder="제품명 검색"
            value={productSearch}
            onChange={handleProductSearchChange}
            onFocus={() => setShowProductSuggestions(productSuggestions.length > 0)}
          />
          <SearchIcon src={images.searchbar} alt="search icon" />
          {showProductSuggestions && (
            <Suggestions>
              {productSuggestions.map((suggestion, index) => (
                <SuggestionItem key={index} onClick={() => handleProductSuggestionClick(suggestion)}>
                  {suggestion}
                </SuggestionItem>
              ))}
            </Suggestions>
          )}
        </SearchWrapper>
        <SearchWrapper>
          <SearchInput
            type="text"
            placeholder="TLC 검색"
            value={tlcSearch}
            onChange={(e) => setTlcSearch(e.target.value)}
          />
          <SearchIcon src={images.searchbar} alt="search icon" />
        </SearchWrapper>
        {/* <SearchSubmitButton onClick={handleSearchSubmit}>추적</SearchSubmitButton> */}
      </SearchFilter>
    </Container>
  );
};

export default FilterBar2;

// 스타일 정의
const DateRange = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button<{ active?: boolean }>`
  padding: 6px 12px;
  border: 1px solid ${({ active }) => (active ? '#4c51c6' : '#ddd')};
  border-radius: 4px;
  background-color: ${({ active }) => (active ? '#f5f7ff' : '#fff')};
  color: ${({ active }) => (active ? '#4c51c6' : '#333')};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #f5f7ff;
    border-color: #4c51c6;
    color: #4c51c6;
  }
`;

const DateFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Input = styled.input`
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;

  &:focus {
    outline: none;
    border-color: #4c51c6;
  }
`;

const Separator = styled.span`
  font-size: 16px;
  color: #333;
`;

const CalendarIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url('/calendar-icon.png');
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const SearchFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;

  &:focus {
    outline: none;
    border-color: #4c51c6;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  right: 12px;
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

const SearchSubmitButton = styled.button`
  padding: 6px 20px;
  border: 1px solid #ddd;
  background-color: #4c51c6;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #3b44b0;
  }
`;

const LargeImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LargeImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

const Suggestions = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 5px 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const SuggestionItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #f5f5f5;
  }
`;
