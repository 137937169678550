import React from 'react';
import styled from 'styled-components';
import { images } from '../../assets/dashboard/images';

interface ExcelModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFileUpload: () => Promise<void>;
  userEmail: string; // userEmail 추가
}

export const ExcelModal: React.FC<ExcelModalProps> = ({
  isOpen,
  closeModal,
  onFileChange,
  onFileUpload,
  userEmail,
}) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  if (!isOpen) return null;

  // 엑셀 파일을 다운로드하는 함수
  const handleExcelDownload = () => {
    const fileUrl = '/harvest.xlsx'; // 엑셀 파일 경로
    window.location.href = fileUrl;
  };

  const handleExcelDownload2 = () => {
    const fileUrl = '/processing.xlsx'; // 엑셀 파일 경로
    window.location.href = fileUrl;
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange(e);
    if (e.target.files?.[0]) {
      setFileName(e.target.files[0].name);
    }
  };

  const handleUploadAndClose = async () => {
    try {
      await onFileUpload();
      closeModal();
    } catch (error) {
      console.error('파일 업로드 실패:', error);
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>양식 업로드</ModalTitle>
        </ModalHeader>
        <ModalContent>
          {/* 양식 다운로드 */}
          <Row>
            <Label>양식</Label>
            <DownloadButton
              onClick={userEmail === 'factory@futuresense.co.kr' ? handleExcelDownload2 : handleExcelDownload}
            >
              <DownloadIcon />
              {userEmail === 'factory@futuresense.co.kr' ? '가공 엑셀 양식 다운로드' : '수확 엑셀 양식 다운로드'}
            </DownloadButton>
          </Row>

          {/* 파일 업로드 */}
          <Row>
            <Label>파일</Label>
            <FileUploadContainer>
              <FileLabel htmlFor="file-upload">파일찾기</FileLabel>
              <FileName>{fileName || '선택된 파일 없음'}</FileName>
              <HiddenFileInput id="file-upload" type="file" onChange={handleFileSelect} />
            </FileUploadContainer>
          </Row>
        </ModalContent>

        {/* Footer */}
        <ModalFooter>
          <ActionButton onClick={handleUploadAndClose} disabled={!fileName}>
            업로드
          </ActionButton>
          <CancelButton onClick={closeModal}>닫기</CancelButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

// 스타일 정의
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray; /* 경계선 추가 */
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const DownloadButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4c51cd;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DownloadIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: url(${images.excel}) no-repeat center;
  background-size: contain;
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FileLabel = styled.label`
  padding: 6px 12px;
  border: 1px solid #4c51cd;
  border-radius: 4px;
  color: #4c51cd;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
`;

const FileName = styled.span`
  font-size: 14px;
  color: #333;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between; /* 버튼 사이 간격 확보 */
  margin-top: 20px;
  gap: 10px;
`;

const ActionButton = styled.button`
  background: #4c51cd;
  color: white;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  flex: 1; /* 버튼이 컨테이너 너비의 반을 차지 */
  text-align: center;

  &:hover {
    background: #3c44b0;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(ActionButton)`
  background: white;
  color: #4c51cd;
  border: 1px solid #4c51cd;

  &:hover {
    background: #f0f0f0;
  }
`;
