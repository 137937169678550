import React from 'react';
import styled, { css } from 'styled-components';

interface ValidationButtonsProps {
  onValidate: () => void;
  onSave: () => void;
  isValidateActive: boolean;
  isSaveActive: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button<{ $active: boolean }>`
  background-color: ${({ $active }) => ($active ? '#3237B4' : '#F4F4F4')};
  color: ${({ $active }) => ($active ? '#FFFFFF' : '#A8A8A8')};
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        background-color: #0056b3;
      }
    `}
`;

const ValidationButtons: React.FC<ValidationButtonsProps> = ({
  onValidate,
  onSave,
  isValidateActive,
  isSaveActive,
}) => {
  return (
    <ButtonContainer>
      <Button onClick={onValidate} $active={isValidateActive}>
        유효성 검사
      </Button>
      <Button onClick={onSave} $active={isSaveActive}>
        저장
      </Button>
    </ButtonContainer>
  );
};

export default ValidationButtons;
