import React, { useState } from 'react';
import styled from 'styled-components';
import { images } from '../../../src/assets/dashboard/images';

const FilterBar: React.FC = () => {
  const [activeRange, setActiveRange] = useState<string>('오늘'); // 선택된 기간 필터
  const [startDate, setStartDate] = useState<string>(''); // 시작 날짜
  const [endDate, setEndDate] = useState<string>(''); // 종료 날짜
  const [productSearch, setProductSearch] = useState<string>(''); // 제품명 검색
  const [tlcSearch, setTlcSearch] = useState<string>(''); // TLC 검색

  const handleRangeClick = (range: string) => {
    setActiveRange(range);
  };

  const handleSearchSubmit = () => {
    console.log('검색 실행:', {
      productSearch,
      tlcSearch,
      startDate,
      endDate,
      activeRange,
    });
  };

  return (
    <Container>
      {/* 기간 필터 */}
      <DateRange>
        <Button active={activeRange === '오늘'} onClick={() => handleRangeClick('오늘')}>
          오늘
        </Button>
        <Button active={activeRange === '1주일'} onClick={() => handleRangeClick('1주일')}>
          1주일
        </Button>
        <Button active={activeRange === '1개월'} onClick={() => handleRangeClick('1개월')}>
          1개월
        </Button>
        <Button active={activeRange === '3개월'} onClick={() => handleRangeClick('3개월')}>
          3개월
        </Button>
        <Button active={activeRange === '전체'} onClick={() => handleRangeClick('전체')}>
          전체
        </Button>
      </DateRange>

      {/* 날짜 선택 */}
      <DateFilter>
        <Input type="date" placeholder="시작 날짜" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <Separator>~</Separator>
        <Input type="date" placeholder="종료 날짜" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <CalendarIcon />
      </DateFilter>

      {/* 검색 필터 */}
      <SearchFilter>
        <SearchWrapper>
          <SearchInput
            type="text"
            placeholder="제품명 검색"
            value={productSearch}
            onChange={(e) => setProductSearch(e.target.value)}
          />
          <SearchIcon src={images.searchbar} alt="search icon" />
        </SearchWrapper>
        <SearchWrapper>
          <SearchInput
            type="text"
            placeholder="TLC 검색"
            value={tlcSearch}
            onChange={(e) => setTlcSearch(e.target.value)}
          />
          <SearchIcon src={images.searchbar} alt="search icon" />
        </SearchWrapper>
        <SearchSubmitButton onClick={handleSearchSubmit}>검색</SearchSubmitButton>
      </SearchFilter>
    </Container>
  );
};

export default FilterBar;

// 스타일 정의

const DateRange = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button<{ active?: boolean }>`
  padding: 6px 12px;
  border: 1px solid ${({ active }) => (active ? '#4c51c6' : '#ddd')};
  border-radius: 4px;
  background-color: ${({ active }) => (active ? '#f5f7ff' : '#fff')};
  color: ${({ active }) => (active ? '#4c51c6' : '#333')};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #f5f7ff;
    border-color: #4c51c6;
    color: #4c51c6;
  }
`;

const DateFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Input = styled.input`
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;

  &:focus {
    outline: none;
    border-color: #4c51c6;
  }
`;

const Separator = styled.span`
  font-size: 16px;
  color: #333;
`;

const CalendarIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url('/calendar-icon.png'); /* 아이콘 경로 */
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const SearchSubmitButton = styled.button`
  padding: 6px 20px;
  border: 1px solid #ddd; /* 기본 테두리 추가 */
  background-color: white; /* 배경색을 흰색으로 설정 */
  color: black; /* 텍스트 색상 */
  font-size: 14px;
  border-radius: 4px; /* 둥근 모서리 */
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5; /* hover 시 배경색 변경 */
    border-color: #4c51c6; /* hover 시 테두리 색상 변경 */
  }
`;

//
const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const SearchFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 40px; /* 검색 필드 사이 간격 조정 */
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px 40px 8px 12px; /* 왼쪽 패딩 조정하여 아이콘 공간 확보 */
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #4c51c6;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  right: 12px; /* 입력 필드 오른쪽에 배치 */
  width: 16px;
  height: 16px;
  pointer-events: none; /* 클릭 이벤트를 차단 */
`;
