import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

type TLCConnectionProps = {
  excelData: any[]; // 엑셀 데이터
  selectedRows: number[]; // 선택된 행 인덱스
  onUpdateRows: (updates: { index: number; 항목: string; TLC: string; 날짜: string }[]) => void; // 업데이트 핸들러
  onUpdateStatus: (updatedData: any[]) => void; // 상태 업데이트 핸들러
};

const defaultTLCMapping: Record<string, string> = {
  시금치: '1010129884',
  당근: '1010129888',
  쌀: '1010129889',
  고추장: '1010129876',
};

const defaultDate = '2024-10-10'; // 기본 날짜 값

const TLCConnection: React.FC<TLCConnectionProps> = ({ excelData, selectedRows, onUpdateRows, onUpdateStatus }) => {
  const [updatedRows, setUpdatedRows] = useState<{ index: number; 항목: string; TLC: string; 날짜: string }[]>([]);

  if (selectedRows.length === 0) {
    return null; // 선택된 데이터가 없으면 null 반환
  }

  // Check if any selected row has a "Compliant" status
  const hasCompliantRow = selectedRows.some((index) => excelData[index]?.상태 === 'Compliant');

  if (hasCompliantRow) {
    return null; // 숨김 처리
  }

  const handleFieldChange = (index: number, field: string, value: string, 항목: string) => {
    setUpdatedRows((prev) => {
      const existing = prev.find((item) => item.index === index && item.항목 === 항목);
      if (existing) {
        return prev.map((item) => (item.index === index && item.항목 === 항목 ? { ...item, [field]: value } : item));
      }

      return [
        ...prev,
        {
          index,
          항목,
          TLC: field === 'TLC' ? value : defaultTLCMapping[항목],
          날짜: field === '날짜' ? value : defaultDate,
        },
      ];
    });
  };

  const handleConnect = () => {
    const updatedData = excelData.map((row, index) =>
      selectedRows.includes(index)
        ? {
            ...row,
            상태: 'Compliant', // 상태값 변경
            TLC: '1', // TLC 값 1로 변경
          }
        : row
    );

    onUpdateStatus(updatedData); // 부모 컴포넌트로 상태 업데이트 전달
    onUpdateRows(updatedRows); // 연결한 행 데이터를 부모 컴포넌트에 전달

    setUpdatedRows([]); // 로컬 상태 초기화
    toast.success('TLC가 연결되었습니다.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const selectedData = selectedRows.map((index) => ({ ...excelData[index], index }));
  const items = ['시금치', '당근', '쌀', '고추장'];

  // Check if all fields are selected
  const allFieldsFilled = updatedRows.length === selectedData.length * items.length;

  return (
    <Container>
      <Title>원부재료 TLC 연결 ({selectedData.length})</Title>
      <Table>
        {selectedData.map((row, i) => (
          <Row key={i}>
            {items.map((item) => (
              <CellGroup key={`${row.index}-${item}`}>
                <Cell>{item}</Cell>
                <Select
                  value={
                    updatedRows.find((r) => r.index === row.index && r.항목 === item)?.날짜 ||
                    row['날짜'] ||
                    defaultDate
                  }
                  onChange={(e) => handleFieldChange(row.index, '날짜', e.target.value, item)}
                >
                  <option value="">선택하세요</option>
                  <option value="2024-10-10">2024-10-10</option>
                  <option value="2024-10-11">2024-10-11</option>
                  <option value="2024-10-12">2024-10-12</option>
                </Select>
                <Select
                  value={
                    updatedRows.find((r) => r.index === row.index && r.항목 === item)?.TLC ||
                    row['TLC'] ||
                    defaultTLCMapping[item]
                  }
                  onChange={(e) => handleFieldChange(row.index, 'TLC', e.target.value, item)}
                >
                  <option value="">선택하세요</option>
                  <option value="20231001-KB-04">093004852098</option>
                  <option value="20231001-KB-04">093004852123</option>
                </Select>
              </CellGroup>
            ))}
          </Row>
        ))}
      </Table>
      <Actions>
        <ConnectButton onClick={handleConnect} disabled={!allFieldsFilled}>
          연결
        </ConnectButton>
      </Actions>
    </Container>
  );
};

export default TLCConnection;

// 스타일 정의
const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const CellGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Cell = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

const Select = styled.select`
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 10px;

  &:focus {
    outline: none;
    border-color: #4caf50;
    box-shadow: 0 0 4px rgba(76, 175, 80, 0.3);
  }
`;

const Actions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`;

const ConnectButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #3b8c3a;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
