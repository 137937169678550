// ExcelDownload.tsx
import React from 'react';
import styled from 'styled-components';

const DownloadButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  border-radius: 4px;
  transition: background-color 0.2s;
  margin-left: 10px;
  width: 200px;
  font-size: 16px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ExcelDownload: React.FC = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/harvesting_form.xlsx`;
    link.download = 'harvesting_form.xlsx';
    link.click();
  };

  return <DownloadButton onClick={handleDownload}>엑셀 다운로드</DownloadButton>;
};

export default ExcelDownload;
