// // import React, { useEffect, useState } from 'react';
// // import styled from 'styled-components';
// // import Wallet from 'src/components/walletbtn/WalletComponent';
// // import { useSelector } from 'react-redux';
// // import { RootState } from 'src/store/store';
// // import axios from 'axios';
// // import { API_BASE_URL } from 'src/utils/utils';

// // const DashboardContainer = styled.div`
// //   background-color: ${({ theme }) => theme.colors.white};
// //   position: relative;
// //   display: flex; /* Flexbox 사용 */
// //   flex-direction: column; /* 세로 방향으로 정렬 */
// //   align-items: center; /* 가로 중앙 정렬 */
// // `;

// // const DashboardHeader = styled.div`
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// //   border-bottom: 1px solid #c1c1c1;
// //   width: 100%; /* 헤더가 전체 너비를 차지하도록 */
// // `;

// // const DashboardTitle = styled.h1`
// //   color: #0f0f0f;
// //   font-size: 20px;
// //   font-weight: 600;
// //   padding: 20px;
// // `;

// // const Dashboard: React.FC = () => {
// //   const [userData, setUserData] = useState<any>(null);
// //   const [isLoading, setIsLoading] = useState(true);
// //   const user = useSelector((state: RootState) => state.user);

// //   useEffect(() => {
// //     const fetchUserData = async () => {
// //       if (user && user.wallet_addr) {
// //         try {
// //           const walletAddress = user.wallet_addr;
// //           const response = await axios.get(`${API_BASE_URL}/api/user/profile/${walletAddress}`);
// //           setUserData(response.data);
// //         } catch (error) {
// //           console.error('Error fetching user data:', error);
// //           setUserData({
// //             profileImage: 'default-profile.png',
// //             name: 'Default User',
// //             expertise: 'Unknown',
// //             points: 0,
// //             xp: 0,
// //             stats: {
// //               deal: 0,
// //               discover: 0,
// //               contribution: 0,
// //               governance: 0,
// //             },
// //           });
// //         } finally {
// //           setIsLoading(false); // 로딩 완료
// //         }
// //       } else {
// //         setIsLoading(false); // user 또는 wallet_addr이 없으면 로딩 완료
// //       }
// //     };

// //     fetchUserData();
// //   }, [user]);

// //   if (isLoading) {
// //     return <div>Loading...</div>; // 데이터 로딩 중에 표시
// //   }

// //   if (!user || !userData) {
// //     return <div>No user data available</div>; // user 또는 userData가 없을 때 처리
// //   }

// //   return (
// //     <DashboardContainer>
// //       <DashboardHeader>
// //         <DashboardTitle>대시보드</DashboardTitle>
// //         <Wallet
// //           address={userData.email || 'N/A'}
// //           username={userData.name || 'Unknown'}
// //           profileImage={userData.profileImage || 'default-profile.png'}
// //           expertise={userData.expertise || 'Unknown'}
// //         />
// //       </DashboardHeader>
// //     </DashboardContainer>
// //   );
// // };

// // export default Dashboard;
// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import Wallet from 'src/components/walletbtn/WalletComponent';
// import { useSelector, useDispatch } from 'react-redux';
// import { RootState } from 'src/store/store';
// import axios from 'axios';
// import { API_BASE_URL } from 'src/utils/utils';
// import { setUserData } from 'src/store/user/UserSlice'; // setUserData 액션 가져오기

// const DashboardContainer = styled.div`
//   background-color: ${({ theme }) => theme.colors.white};
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const DashboardHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 1px solid #c1c1c1;
//   width: 100%;
// `;

// const DashboardTitle = styled.h1`
//   color: #0f0f0f;
//   font-size: 20px;
//   font-weight: 600;
//   padding: 20px;
// `;

// const Dashboard: React.FC = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state: RootState) => state.user);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       if (user.wallet_addr && !user.profileImage) {
//         // profileImage가 없을 때만 요청
//         try {
//           const response = await axios.get(`${API_BASE_URL}/api/user/profile/${user.wallet_addr}`);
//           dispatch(setUserData(response.data)); // Redux에 유저 데이터 저장
//         } catch (error) {
//           console.error('Error fetching user data:', error);
//           dispatch(
//             setUserData({
//               profileImage: 'default-profile.png',
//               name: 'Default User',
//               expertise: 'Unknown',
//               points: 0,
//               xp: 0,
//               stats: {
//                 deal: 0,
//                 discover: 0,
//                 contribution: 0,
//                 governance: 0,
//               },
//             })
//           );
//         } finally {
//           setIsLoading(false);
//         }
//       } else {
//         setIsLoading(false); // wallet_addr이 없으면 로딩 완료
//       }
//     };

//     fetchUserData();
//   }, [user.wallet_addr, user.profileImage, dispatch]);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!user.wallet_addr) {
//     return <div>No user data available</div>;
//   }

//   return (
//     <DashboardContainer>
//       <DashboardHeader>
//         <DashboardTitle>대시보드</DashboardTitle>
//         <Wallet
//           address={user.email_addr || 'N/A'}
//           username={user.name || 'Unknown'}
//           profileImage={user.profileImage || 'default-profile.png'}
//           expertise={user.expertise || 'Unknown'}
//         />
//       </DashboardHeader>
//     </DashboardContainer>
//   );
// };

// export default Dashboard;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Wallet from 'src/components/walletbtn/WalletComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/store';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import { setUserData } from 'src/store/user/UserSlice';

const DashboardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 20px;
`;

const DashboardTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user.wallet_addr) {
        // 기본 데이터 설정
        dispatch(
          setUserData({
            wallet_addr: 'N/A',
          })
        );
        setIsLoading(false);
        return;
      }

      // try {
      //   const response = await axios.get(`${API_BASE_URL}/api/user/profile/${user.wallet_addr}`);
      //   dispatch(setUserData(response.data)); // Redux에 유저 데이터 저장
      // } catch (error) {
      //   console.error('Error fetching user data:', error);
      // } finally {
      //   setIsLoading(false);
      // }
      setIsLoading(false);
    };

    fetchUserData();
  }, [user.wallet_addr, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardContainer>
      <DashboardHeader>
        <DashboardTitle>대시보드</DashboardTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </DashboardHeader>
    </DashboardContainer>
  );
};

export default Dashboard;
