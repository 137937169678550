import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Wallet from 'src/components/walletbtn/WalletComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/store';
import { setUserData } from 'src/store/user/UserSlice';
import FilterBar from 'src/components/table/FilterBar';
import ExcelTable2 from 'src/components/table/ExcelTable2';
import * as XLSX from 'xlsx';

const SearchData: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab2, setActiveTab2] = useState('incoming');
  const [excelData, setExcelData] = useState<any[]>([]);
  const [excelData2, setExcelData2] = useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [disabledTabs] = useState(['harvest', 'shipment']);

  const loadDefaultExcelData = async () => {
    try {
      const response = await fetch('/harvest_default.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      setExcelData(jsonData);
      setExcelData2([]);
    } catch (error) {
      console.error('Failed to load Excel file:', error);
    }
  };

  const loadDefaultExcelData2 = async () => {
    try {
      const response = await fetch('/processing_default.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      setExcelData2(jsonData);
      setExcelData([]);
    } catch (error) {
      console.error('Failed to load Excel file:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user.wallet_addr) {
        dispatch(
          setUserData({
            wallet_addr: 'N/A',
          })
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };
    loadDefaultExcelData();
    fetchUserData();
  }, [user.wallet_addr, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleTabChange2 = async (tab: string) => {
    if (!disabledTabs.includes(tab)) {
      setActiveTab2(tab);
      if (tab === 'incoming') {
        await loadDefaultExcelData(); // harvest_default.xlsx 로드
      } else if (tab === 'processing') {
        await loadDefaultExcelData2(); // processing_default.xlsx 로드
      }
    }
  };

  const handleUpdateRow = (index: number, updatedData: any) => {
    setExcelData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedData;
      return newData;
    });
  };

  const handleRowSelect = (index: number) => {
    setSelectedRows((prev) => (prev.includes(index) ? prev.filter((id) => id !== index) : [...prev, index]));
  };

  return user.email_addr === 'factory@futuresense.co.kr' ? (
    <DashboardContainer>
      <DashboardHeader>
        <DashboardTitle></DashboardTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </DashboardHeader>
      <FilterBar />

      <TabsContainer>
        <Tab
          isActive={activeTab2 === 'harvest'}
          disabled={true} // '수확' 비활성화
          onClick={() => handleTabChange2('harvest')}
        >
          수확
        </Tab>
        <Tab
          isActive={activeTab2 === 'shipment'}
          disabled={disabledTabs.includes('shipment')}
          onClick={() => handleTabChange2('shipment')}
        >
          출고
        </Tab>
        <Tab
          isActive={activeTab2 === 'incoming'}
          disabled={false} // '입고' 활성화
          onClick={() => handleTabChange2('incoming')}
        >
          입고
        </Tab>
        <Tab
          isActive={activeTab2 === 'processing'}
          disabled={false} // '가공' 활성화
          onClick={() => handleTabChange2('processing')}
        >
          가공
        </Tab>
        <Tab
          isActive={activeTab2 === 'shipment'}
          disabled={disabledTabs.includes('shipment')}
          onClick={() => handleTabChange2('shipment')}
        >
          출고
        </Tab>
        <Tab
          isActive={activeTab2 === 'incoming'}
          disabled={true} // '가공' 활성화
          onClick={() => handleTabChange2('incoming')}
        >
          입고
        </Tab>
        <Tab
          isActive={activeTab2 === 'shipment'}
          disabled={disabledTabs.includes('shipment')}
          onClick={() => handleTabChange2('shipment')}
        >
          출고
        </Tab>
      </TabsContainer>
      {(activeTab2 === 'incoming' || activeTab2 === 'processing') && (
        <ExcelTable2
          data={activeTab2 === 'incoming' ? excelData : excelData2}
          onEdit={handleUpdateRow}
          onRowSelect={handleRowSelect}
          selectAll={isSelectAll}
          handleSelectAll={() => {
            setIsSelectAll((prev) => !prev);
            setSelectedRows(!isSelectAll ? (activeTab2 === 'incoming' ? excelData : excelData2).map((_, i) => i) : []);
          }}
          onSaveEdit={(row) => console.log('Saved data:', row)}
        />
      )}
    </DashboardContainer>
  ) : (
    <AccessDeniedContainer>
      <AccessDeniedMessage>Access restricted to manufacturers only.</AccessDeniedMessage>
    </AccessDeniedContainer>
  );
};

export default SearchData;

// 스타일 정의
const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
`;

const Tab = styled.div<{ isActive: boolean; disabled: boolean }>`
  padding: 10px 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-bottom: 3px solid ${({ isActive, disabled }) => (isActive && !disabled ? '#4c51cd' : 'transparent')};
  color: ${({ isActive, disabled }) => (disabled ? '#bcbcbc' : isActive ? '#4c51cd' : '#6b6b6b')};
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ disabled }) => (disabled ? '#bcbcbc' : '#4c51cd')};
  }

  & + & {
    margin-left: 10px;
  }
`;

const DashboardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 20px;
`;

const DashboardTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
`;

const AccessDeniedMessage = styled.h1`
  font-size: 24px;
  color: #7035a7;
  font-weight: bold;
  text-align: center;
`;
