import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import Wallet from 'src/components/walletbtn/WalletComponent';

const ProductVendorContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const ProductVendorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
`;

const ProductVendorTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

const ProductVendor: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.wallet_addr) {
        try {
          const walletAddress = user.wallet_addr;
          const response = await axios.get(`${API_BASE_URL}/api/user/profile/${walletAddress}`);
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserData({
            profileImage: 'default-profile.png',
            name: 'Default User',
            expertise: 'Unknown',
            points: 0,
            xp: 0,
            stats: {
              deal: 0,
              discover: 0,
              contribution: 0,
              governance: 0,
            },
          });
        } finally {
          setIsLoading(false); // 로딩 완료
        }
      } else {
        setIsLoading(false); // user 또는 wallet_addr이 없으면 로딩 완료
      }
    };

    fetchUserData();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>; // 데이터 로딩 중에 표시
  }

  if (!user || !userData) {
    return <div>No user data available</div>; // user 또는 userData가 없을 때 처리
  }

  return (
    <ProductVendorContainer>
      <ProductVendorHeader>
        <ProductVendorTitle>ProductVendor</ProductVendorTitle>
        <Wallet address={user.wallet_addr || 'N/A'} />
      </ProductVendorHeader>
      {/* <ProfileCard />
      <UserStats /> */}
    </ProductVendorContainer>
  );
};

export default ProductVendor;
