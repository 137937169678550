import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import Wallet from 'src/components/walletbtn/WalletComponent';
import { images } from 'src/assets/dashboard/images';
import FilterBar2 from 'src/components/table/FilterBar2';
import ExcelTable from 'src/components/table/ExcelTable';
import * as XLSX from 'xlsx';

const TrackHistory: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab2, setActiveTab2] = useState('tracking'); // Default to 'tracking' tab
  const [excelData, setExcelData] = useState<any[]>([]); // 엑셀 데이터 상태
  const [file, setFile] = useState<File | null>(null); // 업로드된 파일 상태
  const user = useSelector((state: RootState) => state.user);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false); // State to control visibility

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.wallet_addr) {
        try {
          const walletAddress = user.wallet_addr;
          const response = await axios.get(`${API_BASE_URL}/api/user/profile/${walletAddress}`);
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (activeTab2 === 'data') {
      loadDefaultExcelData();
    }
  }, [activeTab2]);

  const loadDefaultExcelData = async () => {
    try {
      const response = await fetch('/original_1.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(sheet);
      setExcelData(data);
    } catch (error) {
      console.error('Error loading default Excel file:', error);
      alert('Failed to load the default Excel file');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      const mimeType = selectedFile.type;

      if (
        mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileExtension === 'xlsx'
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const workbook = XLSX.read(reader.result, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet);
            setExcelData(data);
            setFile(selectedFile);
          } catch (error) {
            alert('Invalid Excel file');
          }
        };
        reader.readAsBinaryString(selectedFile);
      } else {
        alert('Please upload a valid Excel file');
      }
    }
  };

  const handleSearch = () => {
    setShowImage(true);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle visibility
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return user.email_addr === 'factory@futuresense.co.kr' ? (
    <TrackHistoryContainer>
      <TrackHistoryHeader>
        <TrackHistoryTitle></TrackHistoryTitle>
        <Wallet address={user.email_addr || 'N/A'} />
      </TrackHistoryHeader>
      <FilterBar2 onSearch={handleSearch} />
      <SearchButtonContainer>
        <SearchButton onClick={toggleVisibility}>{isVisible ? '추적' : '추적  '}</SearchButton>
      </SearchButtonContainer>
      <TabsContainer>
        <Tab isActive={activeTab2 === 'tracking'} onClick={() => setActiveTab2('tracking')}>
          트래킹뷰
        </Tab>
        <Tab isActive={activeTab2 === 'data'} onClick={() => setActiveTab2('data')}>
          데이터뷰
        </Tab>
      </TabsContainer>

      {activeTab2 === 'tracking' && isVisible && (
        <LargeImageContainer>
          <LargeImage src={images.tracking} alt="Tracking" />
        </LargeImageContainer>
      )}

      {activeTab2 === 'data' && isVisible && (
        <div>
          {file && <div>Uploaded File: {file.name}</div>}
          <ExcelTable
            data={excelData}
            onEdit={(row) => console.log('저장된 데이터:', row)}
            onRowSelect={() => {}}
            selectAll={false}
            handleSelectAll={() => {}}
            onSaveEdit={(row) => console.log('저장된 데이터:', row)}
          />
        </div>
      )}
    </TrackHistoryContainer>
  ) : (
    <AccessDeniedContainer>
      <AccessDeniedMessage>Access restricted to manufacturers only.</AccessDeniedMessage>
    </AccessDeniedContainer>
  );
};

export default TrackHistory;

// 스타일 정의
const TrackHistoryContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const TrackHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
`;

const TrackHistoryTitle = styled.h1`
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const Tab = styled.button<{ isActive: boolean }>`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.isActive ? '#007bff' : '#999')};
  background-color: ${(props) => (props.isActive ? '#fff' : '#f0f0f0')};
  border: 1px solid ${(props) => (props.isActive ? '#007bff' : '#ddd')};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#fff' : '#e0e0e0')};
    border-color: ${(props) => (props.isActive ? '#0056b3' : '#ccc')};
  }

  &:focus {
    outline: none;
  }
`;

const LargeImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LargeImage = styled.img`
  max-width: 70%;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 10px;
`;

const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
`;

const AccessDeniedMessage = styled.h1`
  font-size: 24px;
  color: #7035a7;
  font-weight: bold;
  text-align: center;
`;

const ToggleButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;
const SearchButtonContainer = styled.div`
  position: fixed; /* fixed를 사용해 화면에서 고정된 위치 */
  top: 50px; /* 화면 위에서 50px */
  right: 20px; /* 화면 우측에서 20px */
  z-index: 100; /* 더 높은 우선순위 */
  display: flex; /* 자식 요소 정렬을 위한 flexbox */
  align-items: center; /* 수직 정렬 */
  justify-content: center; /* 수평 정렬 */
`;

const SearchButton = styled.button`
  padding: 10px 20px; /* 버튼 크기 조정 */
  background-color: #007bff; /* 배경 색 */
  color: white; /* 텍스트 색 */
  font-size: 14px; /* 폰트 크기 */
  font-weight: bold; /* 폰트 두께 */
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 둥근 모서리 */
  cursor: pointer; /* 마우스 커서 */
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3; /* hover 시 색상 변경 */
  }

  &:focus {
    outline: none; /* 포커스 상태에서 테두리 제거 */
  }
`;
