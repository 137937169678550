import styled from 'styled-components';

export const AdminBoard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(75, 75, 75, 0.5);
`;

export const Container = styled.div`
  width: 550px;
  height: 400px;
  border-radius: 10px;
  padding: 50px;
  background: #fff;
  text-align: center;
`;

export const Title = styled.span`
  color: var(--Purple-900, #7c4dff);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubTitle = styled.span`
  color: var(--Purple-900, #7c4dff);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Form = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: end; /* 버튼을 중앙에 위치 */
`;

export const Label = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 15px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 10px;
`;

export const Button = styled.button`
  width: 100px;
  height: 40px;
  padding: 0px;
  background: #6c63ff;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: var(--Purple-900, #3237b4);

  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: #4c51cd;
  }
`;

export const ForgotPassword = styled.a`
  text-align: right;
  margin-top: -5px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    color: #000;
  }
`;

export const EyeIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 12px;
  width: 20px;
  height: 30px;
  cursor: pointer;
`;

export const PasswordWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const LogoTitle = styled.img`
  width: 150px;
  height: 40px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Label을 왼쪽에 정렬 */
  width: 100%;
`;
