import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // 기본 스타일 추가

interface ExcelTableProps {
  data: any[];
  onEdit: (index: number, updatedData: any) => void; // 기존 onEdit
  onRowSelect: (index: number) => void;
  selectAll: boolean;
  handleSelectAll: () => void;
  onSaveEdit: (updatedRow: any) => void; // onSaveEdit 추가
}

type ProductName = '[Fs-01] 시금치' | '[Fs-02] 당근';
type ProductDetails = {
  LOT: string;
  TLC: string;
  제품코드: string;
  브랜드명: string;
  품목: string;
  품종: string;
  포장크기: string;
  단위: string;
  포장형태: string;
};

type FarmDetails = {
  구역명: string;
  전화번호: string;
  우편번호: string;
  주소: string;
  국가명: string;
  도시: string;
  주지역: string;
};

type CompanyDetails = {
  전화번호: string;
  우편번호: string;
  주소: string;
  국가명: string;
  도시: string;
  주지역: string;
};
const productData: Record<ProductName, ProductDetails> = {
  '[Fs-01] 시금치': {
    LOT: 'fs11012',
    TLC: '093004852098',
    제품코드: 'SP001',
    브랜드명: 'FreshFarm',
    품목: '채소',
    품종: '시금치 1호',
    포장크기: '500g',
    단위: 'g',
    포장형태: '플라스틱',
  },
  '[Fs-02] 당근': {
    LOT: 'fs24012',
    TLC: '093004852123',
    제품코드: 'CR002',
    브랜드명: 'FreshFarm',
    품목: '채소',
    품종: '당근 2호',
    포장크기: '1kg',
    단위: 'kg',
    포장형태: '종이',
  },
};

const farmData: Record<string, FarmDetails> = {
  시금치농장: {
    구역명: '재배지 A',
    전화번호: '010-1234-5678',
    우편번호: '12345',
    주소: '서울특별시 00구 00동',
    국가명: '대한민국',
    도시: '서울',
    주지역: '서울시',
  },
  당근농장: {
    구역명: '재배지 A',
    전화번호: '010-1234-5678',
    우편번호: '12345',
    주소: '서울특별시 00구 00동',
    국가명: '대한민국',
    도시: '서울',
    주지역: '서울시',
  },
};

const companyData: Record<string, CompanyDetails> = {
  맑은제조업체: {
    전화번호: '02-9876-5432',
    우편번호: '67890',
    주소: '서울특별시 00구 00동',
    국가명: '대한민국',
    도시: '서울',
    주지역: '서울시',
  },
  푸른제조업체: {
    전화번호: '02-9876-5432',
    우편번호: '67890',
    주소: '서울특별시 00구 00동',
    국가명: '대한민국',
    도시: '서울',
    주지역: '서울시',
  },
};

const ExcelTable2: React.FC<ExcelTableProps> = ({ data, onEdit, onRowSelect, selectAll, handleSelectAll }) => {
  const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(data.length).fill(false));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [formData, setFormData] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<string>(''); // 선택된 제품
  const [details, setDetails] = useState<ProductDetails | null>(null); // 제품 상세 정보 상태
  const [selectedFarm, setSelectedFarm] = useState<string>(''); // 선택된 농장
  const [farmDetails, setFarmDetails] = useState<FarmDetails | null>(null); // 농장 상세 정보 상태
  const [selectedCompany, setSelectedCompany] = useState<string>(''); // 선택된 업체
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails | null>(null); // 업체 상세 정보 상태

  // 전체 선택/해제 처리
  useEffect(() => {
    if (selectAll) {
      setCheckedItems(new Array(data.length).fill(true)); // 전체 체크박스 체크
    } else {
      setCheckedItems(new Array(data.length).fill(false)); // 전체 체크박스 해제
    }
  }, [selectAll, data.length]);

  const handleViewClick = () => {
    const url = 'https://scan.dev.food4chain.com/tx/0x752220e8a2e3a8de5bbba4a28718a73974de41aba8d764b4bf2b98f36c24c982';
    window.open(url, '_blank'); // 새 창으로 링크 열기
  };

  const handleEditClick = (index: number) => {
    const rowData = data[index];
    setSelectedRowData(rowData);

    // 기본 정보 설정
    setFormData({
      수확날짜: rowData['수확날짜'] || '', // 기본 날짜
      수량: rowData['수량'] || '', // 기본 수량
    });

    // 기본 값으로 제품명, 농장명, 업체명 설정
    const product = rowData['제품명'] as ProductName;
    setSelectedProduct(product);
    setDetails(productData[product]);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };

  const handleSave = () => {
    if (selectedRowData) {
      // 선택된 데이터의 상태 값을 "Compliant"로 강제 변경하고 날짜와 수량 업데이트
      const updatedRow = {
        ...selectedRowData,
        상태: 'Compliant', // 상태 강제 변경
        수량: formData['수량'], // 수정된 수량 반영
        수확날짜: formData['수확날짜'], // 수정된 날짜 반영
      };

      // 데이터 업데이트
      const updatedIndex = data.findIndex((row) => row['제품ID'] === selectedRowData['제품ID']);
      if (updatedIndex !== -1) {
        onEdit(updatedIndex, updatedRow); // 부모 컴포넌트로 수정된 데이터 전달
      }

      handleCloseModal(); // 모달 닫기
    }
    toast.success('수정되었습니다!', {
      position: 'top-right',
      autoClose: 3000, // 3초 후 자동 닫힘
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setIsModalOpen(false); // 모달 닫기
    setSelectedRowData(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 제품 변경 시 처리
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const product = e.target.value as ProductName;
    setSelectedProduct(product);
    setDetails(productData[product]);
  };

  // 농장 변경 시 처리
  const handleFarmChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const farm = e.target.value;
    setSelectedFarm(farm);
    setFarmDetails(farmData[farm] || null);
  };

  // 업체 변경 시 처리
  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const company = e.target.value;
    setSelectedCompany(company);
    setCompanyDetails(companyData[company] || null);
  };

  // 체크박스 상태 변경 시 처리
  const handleCheckboxChange = (index: number) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
    onRowSelect(index); // 해당 항목의 선택 상태 변경
  };

  const isSaveDisabled =
    !selectedProduct || // 제품 선택 여부
    !selectedFarm || // 농장 선택 여부
    !selectedCompany || // 업체 선택 여부
    !formData['수확날짜'] || // 수확 날짜 입력 여부
    !formData['수량'] || // 수량 입력 여부
    !selectedRowData; // 선택된 행 데이터 여부
  return (
    <div>
      <Table>
        <thead>
          <StyledTr>
            <Th>
              <Checkbox type="checkbox" checked={selectAll} onChange={handleSelectAll} />
            </Th>
            <Th>제품명</Th>
            <Th>제품ID</Th>
            <Th>수량</Th>
            <Th>수확날짜</Th>
            <Th>TLC</Th>
            <Th>가공 위치(업체)</Th>
            <Th>수령 위치(시설)</Th>
            <Th>상태</Th>
            <Th>TxHash</Th>
          </StyledTr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <StyledTr>
              <NoDataCell colSpan={10}>No data available</NoDataCell>
            </StyledTr>
          ) : (
            data.map((row, index) => (
              <StyledTr key={index}>
                <Td>
                  <Checkbox
                    type="checkbox"
                    checked={checkedItems[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </Td>
                <Td>{row['제품명']}</Td>
                <Td>{row['제품ID']}</Td>
                <Td>{row['수량']}</Td>
                <Td>{row['수확날짜']}</Td>
                <Td>{row['TLC']}</Td>
                <Td>{row['가공 위치(업체)']}</Td>
                <Td>{row['수령 위치(시설)']}</Td>
                <StatusCell status={row['상태']}>{row['상태']}</StatusCell>
                <Td>
                  <EditButton onClick={handleViewClick}>View</EditButton>
                </Td>
              </StyledTr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ExcelTable2;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledTr = styled.tr`
  &:hover {
    background-color: #ebecfa; /* 행 전체 호버 시 배경색 */
  }
`;

const Th = styled.th`
  background-color: #f7f7f7;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border: 1px solid #ddd;
  color: #333;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  color: #555;

  &:hover {
    background-color: #ebecfa;
  }
`;

const NoDataCell = styled.td`
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  color: gray;
  height: 600px;
  background-color: #fafafa;
  vertical-align: middle;
  width: 100%;
`;

const EditButton = styled.button`
  background-color: white; /* 배경색 흰색 */
  color: black; /* 텍스트 색 검정 */
  padding: 8px 16px;
  border: 1px solid lightgray; /* 연한 회색 테두리 */
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const Checkbox = styled.input`
  margin: 0;
`;

const StatusCell = styled.td<{ status: string }>`
  color: ${({ status }) => (status === 'Non-compliant' ? 'red' : status === 'Compliant' ? 'green' : 'black')};
  font-weight: bold;
  border: 1px solid #ddd; // 기존 테이블 border 유지
  text-align: center; // 가운데 정렬
  padding: 12px; // 기존 패딩 유지
`;
