// src/assets/dashboard/images.ts
import dashboardIcon from './dashboard.svg';
import dealIcon from './deal.png';
import discoverIcon from './discover.png';
import contributionIcon from './contribution.png';
import governanceIcon from './governance.png';
import notificationsIcon from './notifications.png';
import sidebarLogo from './sidebarLogo.png';
import profileDefaultIcon from './profile_default.png';
import pointsIcon from './error.png';
import editIcon from './edit_icon.png';
import copyIcon from './copy_icon.png';
import openSeaIcon from './openSea.png';
import editIcon2 from './edit.png';
import default_profile from './default_image.png';
import edit_black from './edit_black.png';
import varIcon from './bar.svg';
import recordhistoryIcon from './record_history.svg';
import trackhistoryIcon from './track_history.svg';
import reportIcon from './report.svg';
import productvendorIcon from './product_vendor.svg';
import settingsIcon from './settings.svg';
import dashboardIconActive from './dashboardIconActive.svg';
import recordhistoryIconActive from './recordhistoryIconActive.svg';
import searchdata from './searchdata.svg';
import searchdataActive from './searchdataActive.svg';
import trackhistoryActive from './track_historyActive.svg';
import reportActive from './reportActive.svg';
import productvendorActive from './product_vendorActive.svg';
import settingsActive from './settingsActive.svg';
import plus from './plus.svg';
import upload from './upload.svg';
import uploadActive from './uploadActive.svg';
import download from './download.svg';
import excel from './excel.svg';
import txsvg from './txhash.svg';
import searchbar from './searchbar.svg';
import tracking from './tracking.png';

export const images = {
  tracking,
  searchbar,
  txsvg,
  excel,
  download,
  upload,
  uploadActive,
  plus,
  settingsActive,
  productvendorActive,
  reportActive,
  trackhistoryActive,
  searchdata,
  searchdataActive,
  varIcon,
  dashboardIconActive,
  dashboardIcon,
  recordhistoryIcon,
  recordhistoryIconActive,
  trackhistoryIcon,
  reportIcon,
  productvendorIcon,
  settingsIcon,
  dealIcon,
  discoverIcon,
  contributionIcon,
  governanceIcon,
  notificationsIcon,
  sidebarLogo,
  profileDefaultIcon,
  pointsIcon,
  editIcon,
  editIcon2,
  copyIcon,
  openSeaIcon,
  default_profile,
  edit_black,
};
